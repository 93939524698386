import { createSelector } from "reselect";

import { getSites, getSiteById } from "services/redux/selectors/sites";
import { createGetEventMetaById } from "services/redux/selectors/eventMeta";

export const getFailedToCloseReport = (state) => state.reports.failedtoclose.index;
export const getFailedToCloseFilter = (state) => state.reports.failedtoclose.index.filter;
export const getFailedToCloseReportExpanded = (state) => state.reports.failedtoclose.index.expanded;

export const getExpanded = (site) => {
  if (site && site.expanded === false) return false;

  return site && site.areas.length > 0 && site.expanded;
};

export const getExpandedRowsCount = (expanded) =>
  Object.keys(expanded).filter((enterprise_site_id) => expanded[enterprise_site_id]).length;

export const getFailedToCloseData = createSelector(
  [getFailedToCloseReport, getSites, getFailedToCloseReportExpanded, createGetEventMetaById],
  (report, sites, expanded, getEventMetaById) => {
    if (report && report.data && report.data.sites) {
      return Object.entries(report.data.sites).map(([sudoSiteId, siteReportData]) => {
        const siteData = getSiteById(sites, parseInt(sudoSiteId));

        // get the event metadata for the site perimeter
        const perimeterEventMeta = siteReportData.perimeter ? getEventMetaById(siteReportData.perimeter) : null;

        // get the event metadata for the site state
        const stateEventMeta = siteReportData.state ? getEventMetaById(siteReportData.state) : null;

        // get the event metadata for the area states
        const areas = siteReportData.areas.map((area) => {
          return {
            ...area,
            area_state_event_meta: area.state ? getEventMetaById(area.state) : null, 
          }
        });

        return {
          sudo_site_id: sudoSiteId,
          ...siteReportData, // report data for the site from the endpoint
          ...siteData, // general site data
          perimeter_event_meta: perimeterEventMeta, // event meta data for the perimeter event of the site
          state_event_meta: stateEventMeta, // event meta data for the state event of the site
          areas: [
            ...areas, // areas data, including the event metadata
          ],
          expanded: typeof expanded[sudoSiteId] === "undefined" ? null : expanded[sudoSiteId],
        }
      });
    } else {
      return [];
    }
  }
);

const siteDataContainsFilter = (siteData, filter, fieldToFilter) => {
  if (Object.keys(filter.show).length === 0) return true; // no filters set, so we'll want to return all the results

  if (!siteData[fieldToFilter]) return true;

  // loop through the filters and see if we get a match on the field we're filtering on
  return Object.entries(filter.show).some(([key, value]) => {
    return (siteData[fieldToFilter].search(key) !== -1 && value === true);
  })
}

export const getFailedToCloseDataFiltered = createSelector(
  [getFailedToCloseData, getFailedToCloseFilter],
  (failedToCloseData, failedToCloseFilter) => {
    const filterToApply = failedToCloseFilter[failedToCloseFilter.type];
    const fieldToFilter = failedToCloseFilter.type === "store_types" ? "store_type" : "group_name";

    // loop through the sites data and filter based on the type
    return failedToCloseData.filter((siteData) => {
      return siteDataContainsFilter(siteData, filterToApply, fieldToFilter);
    });
    
  }
);

export const getFailedToCloseTotals = createSelector(
  [getFailedToCloseReport], 
  (report) => {
    const totalsArray = report.filter.type === "store_types" ? report.data.totals.store_types : report.data.totals.groups;

    const data = totalsArray ? totalsArray.map(({ name, value }) => { return { name, value } }) : [];

    const colors = totalsArray ? totalsArray.map(({ color }) => color ) : [];

    return {
      data,
      colors,
      sites: report.data.totals.sites,
    };

});