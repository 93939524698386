import { all, takeLeading, takeEvery } from "redux-saga/effects";

import * as facewatch from "./facewatch";
import * as exception from "./exception/";
import * as overview from "./overview/overview";
import * as scheduled from "./scheduled";

import * as outstanding from "./outstanding";
import * as openclosebyarea from "./openclosebyarea";
import * as failedtoclose from "./failedtoclose";

import cacheIndexes from "services/redux/sagas/cacheIndexes";

export function* rootSaga() {
  // takeLeading

  yield takeEvery("LOAD_FACEWATCH_START", facewatch.loadStart);

  yield takeLeading("LOAD_REPORTS_OVERVIEW_SUCCESS", cacheIndexes.cacheMe);
  // yield takeLeading("LOAD_REPORTS_OVERVIEW_START", reportsIndex.loadStart);

  yield all([
    exception.rootSaga(),
    outstanding.rootSaga(),
    openclosebyarea.rootSaga(), // 09/21 - open close by area
    failedtoclose.rootSaga(),
    scheduled.watchLiveRefresh(),
    overview.watchLiveRefresh()
  ]);
}
