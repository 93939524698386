import { createSelector } from "reselect";

import { getReport, getConfig } from "./index";

export const getEventCountFilter = createSelector(
  [getReport],
  report => {
    return report.filter.eventCount.show;
  }
);

export const getEventTypeFilter = createSelector(
  [getReport],
  report => report.filter.eventType.show
);

export const getStoreTypeFilter = createSelector(
  [getReport],
  report => report.filter.store_types.show
);

export const getGroupsFilter = createSelector(
  [getReport],
  report => report.filter.groups.show
);

const filters = {
  eventType: getEventTypeFilter,
  eventCount: getEventCountFilter,
  store_types: getStoreTypeFilter,
  groups: getGroupsFilter,
  none: () => {
    return {};
  }
};
export const getFilter = createSelector(
  [getConfig],
  config => {
    return filters[config.charts.pie.filter.type];
  }
);
