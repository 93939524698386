import React from "react";

import get from "lodash/get";

import { labelContainsFilter } from "services/redux/selectors/reports/scheduled";

import { getExpanded } from "services/redux/selectors/reports/failedtoclose";

import ChildRowColumns from "./ChildRowColumns";

import moment from "services/locale/momentInit.js";

const TrGroupComponent = (props) => {
  const { 
    site,
    filtered,
  } = props;

  const getExpandedData = (site) => {
    return [ // first add in an item containing the data from the root of the site
      {
        ...site,
        area_state_event_meta: { ...site.state_event_meta },
      }
    ].concat(site.areas); // then add in all the area items
  }  
      
  const expandedContent = getExpanded(site)
    ? getExpandedData(site).map((area, index) => { 
      let rowsShown = -1;

      const getStyle = index =>
        get(props, `children.0.props.children.${index}.props.style`);

      return (
        <div
          className="rt-tr sub-row"
          role="row"
          key={site.sudo_site_id + " " + index}>
          {ChildRowColumns.map(cell => {
            // check whether any of the sub-rows contain a filter
            // area
            let areaContainsFilter = false;
            const areaFilter = filtered.find(({ id }) => id === "area");
            if (areaFilter) {
              areaContainsFilter = labelContainsFilter(area.area || "", areaFilter);
            }

            // area name
            let areaNameContainsFilter = false;
            const areaNameFilter = filtered.find(({ id }) => id === "area-name");
            if (areaNameFilter) {
              areaNameContainsFilter = labelContainsFilter(area.area_name || "", areaNameFilter);
            }

            // area state
            let areaStateContainsFilter = false;
            const filter = filtered.find(({ id }) => id === "area-state");
            if (filter) {
              areaStateContainsFilter = labelContainsFilter(area.area_state_event_meta.label, filter);
            }

            // last state event
            let lastStateEventContainsFilter = false;
            const lastStateEventFilter = filtered.find(({ id }) => id === "last-state-event");
            if (lastStateEventFilter) {
              lastStateEventContainsFilter = labelContainsFilter(area.last_state_event || "", lastStateEventFilter);
            }

            // last state change
            let lastStateChangeContainsFilter = false;
            const lastStateChangeFilter = filtered.find(({ id }) => id === "last-state-change");
            if (lastStateChangeFilter) {
              lastStateChangeContainsFilter = labelContainsFilter(moment(area.last_state_change).format("DD/MM/YYYY HH:mm") || "", lastStateChangeFilter);
            }

            rowsShown++;

            const content =
              (cell.content &&
                cell.content({
                  site,
                  area,
                  index,
                  areaContainsFilter,
                  areaNameContainsFilter,
                  areaStateContainsFilter,
                  lastStateEventContainsFilter,
                  lastStateChangeContainsFilter,
                  focus: true
                })) ||
              null;

            return (
              <div
                className={`rt-td ${cell.className || ""}`}
                role="gridcell"
                style={{
                  ...getStyle(rowsShown),
                }}
                key={site.sudo_site_id + " " + index + " " + cell.key}>
                {content}
              </div>
            );
          })}
        </div>
      );
    })
    : null;

  const expandedClass = props.expanded === 1 ? "expanded" : "";

  return (
    <>
      <div
        className={`rt-tr-group ${expandedClass}`}
        role="rowgroup"
        key={site && site.sudo_site_id}>
        {props.children}
        {expandedContent}
      </div>
    </>
  );
}

export default TrGroupComponent;