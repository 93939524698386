import React from "react";
import { connect } from "react-redux";

import ErrorBoundary from "components/Layout/ErrorBoundary";
import ProgressBar from "components/Enterprise/Reports/Common/ProgressBar";
import Pie from "components/Enterprise/Reports/Types/Scheduled/Pie";

import { changeFailedToCloseReportFilter } from "services/redux/actions/";

import { getConfig } from "services/redux/selectors/reports/common/";

import ActiveFilters from "./ActiveFilters";
import DataTable from "./DataTable";

import { default as T } from "services/i18n/Translate";

class PageLayout extends React.Component {
  render() {
    const { reportType, config, loading, nextRefresh, lastRefresh } = this.props;

    const pie = (
      <ErrorBoundary message="This chart currently cannot be shown. Please try again later.">
        <Pie changeReportFilter={changeFailedToCloseReportFilter} />
      </ErrorBoundary>
    );

    const progressBar = (
      <ProgressBar
        nextRefresh={nextRefresh}
        lastRefresh={lastRefresh}
      />
    );

    return (
      <>
        <div className={"row" + loading}>
          <div className="col-md-12">
            <ErrorBoundary>
              <div className="card m-b-30 visual-data">
                <div className="card-body">
                  <div className="row justify-content-between m-2">
                    <div className="mr-4">
                      <h4 className="mt-0 header-title">
                        <T>{config.title}</T>
                      </h4>
                      <p className="text-muted m-b-30 d-inline-block text-truncate w-100">
                        <T>{config.description}</T>
                      </p>
                    </div>
                    <div className="d-flex align-items-end" style={{ marginBottom: "30px" }}>
                      <div>{progressBar}</div>
                    </div>
                  </div>
                  <div className="col-md-12">{pie}</div>
                </div>
              </div>
            </ErrorBoundary>
          </div>
        </div>
        <div className={"row" + loading}>
          <div className="col-md-12">
            <ErrorBoundary>
              <div className="card m-b-30 visual-data">
                <ActiveFilters />
                <DataTable reportType={reportType} reportTitle={""} />
              </div>
            </ErrorBoundary>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    config: getConfig(state, props),
    loading: state.reports.failedtoclose.index.loading,
    nextRefresh: state.reports.failedtoclose.index.nextRefresh || null,
    lastRefresh: state.reports.failedtoclose.index.lastRefresh || null,
  };
};
export default connect(mapStateToProps)(PageLayout);
