import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { DropdownButton, Dropdown } from "react-bootstrap";

import Icon from "components/Icons/Icon";

import {
  exportExcel,
  exportPdf,
  exportCsv
} from "services/redux/actions/utility";

class ExportDropdown extends React.Component {
  exportExcel = () => {
    const { dataResolver, reportTitle } = this.props;

    this.props.dispatch(exportExcel({ dataResolver, reportTitle }));
  };
  exportPDF = () => {
    const { dataResolver, reportTitle } = this.props;

    this.props.dispatch(exportPdf({ dataResolver, reportTitle }));
  };
  exportCsv = () => {
    const { dataResolver, reportTitle } = this.props;

    this.props.dispatch(exportCsv({ dataResolver, reportTitle }));
  };
  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    // TODO: translate
    const ExportExcel = mobile ? null : (
      <Dropdown.Item onClick={() => this.exportExcel()}>
        <Icon className="far fa-file-excel" /> Excel
      </Dropdown.Item>
    );
    // TODO: translate
    const ExportCSV = (
      <Dropdown.Item onClick={() => this.exportCsv()}>
        <Icon className="fas fa-file-csv" /> CSV
      </Dropdown.Item>
    );

    const { dataResolver, reportTitle, dropdownTitle, excludePDF, dispatch, ...rest } = this.props;

    return (
      <DropdownButton
        {...rest}
        id="dropdown-export"
        // TODO: translate
        title={dropdownTitle ? dropdownTitle : "Export"} // 08/21 allow alternative title for export dropdown
        disabled={this.props.disabled}>
        {ExportExcel}
        {ExportCSV}
        {
          !this.props.excludePDF &&
            <Dropdown.Item onClick={() => this.exportPDF()}>
              {/* TODO: translate */}
              <Icon className="far fa-file-pdf" /> PDF
            </Dropdown.Item>
        }
      </DropdownButton>
    );
  }
}
ExportDropdown.propTypes = {
  dataResolver: PropTypes.func.isRequired,
  reportTitle: PropTypes.string.isRequired,
  dropdownTitle: PropTypes.string
};

export default connect()(ExportDropdown);
