import React from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { getFailedToCloseFilter } from "services/redux/selectors/reports/failedtoclose/";
import { getConfig } from "services/redux/selectors/reports/common/";
import { getReportType } from "services/redux/selectors/reports/router";

import { changeFailedToCloseReportFilter } from "services/redux/actions/";

import RemoveFilter from "./RemoveFilter";

import _ from "lodash";

class FailedToCloseFilter extends React.Component {
  render() {
    const { label, filter, totalsData } = this.props;

    const visible = filter[filter.type].show[label];

    const totalData = _.find(totalsData, { "name": label });

    const key = label.toLowerCase().replace(" ", "-");
    if (!visible) return null;
    return (
      <span
        key={key}
        className={`${key} text-light`}
        style={{
          backgroundColor: totalData && totalData.color,
          padding: "0.3rem 0.6rem"
        }}>
        {label}{" "}
        <RemoveFilter
          onClick={e => {
            this.props.dispatch(
              changeFailedToCloseReportFilter({
                show: label,
              })
            );
          }}
        />
      </span>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    reportType: getReportType(state, props),
    config: getConfig(state, props),
    filter: getFailedToCloseFilter(state, props),
    totalsData: state.reports.failedtoclose.index.data.totals[state.reports.failedtoclose.index.data.totals.type],
  };
};

export default withRouter(connect(mapStateToProps)(FailedToCloseFilter));