import React from "react";

import EventCell from "components/Common/DataTable/EventCell/";
import TimezoneDate from "components/Common/TimezoneDate";

import { NA } from "services/redux/selectors";

import moment from "services/locale/momentInit.js";

const today = new moment();

const ChildRowColumns = [
  {
    key: "CUSTOM_ID",
    content: ({ site }) => site.custom_site_ref || NA(),
    className: "text-muted",
  },
  {
    key: "NAME",
    content: ({ site }) => site.name || NA(),
    className: "text-muted",
  },
  {
    key: "STORE_TYPE",
    content: ({ site }) => site.store_type || NA(),
    className: "text-muted",
  },
  {
    key: "CONTRACT_NO",
    content: ({ site }) => site.contract_no || NA(),
    className: "text-muted",
  },
  {
    key: "SYSTEM_TYPE",
    content: ({ site }) => site.system_type || NA(),
    className: "text-muted",
  },
  {
    key: "GROUP",
    content: ({ site }) => site.group_name || NA(),
    className: "text-muted"
  },
  {
    key: "SITE_REF",
    content: ({ site }) => site.site_ref,
    className: "text-muted"
  },
  {
    key: "PERIMETER",
    content: ({ site, focus }) => {
      return (
        site.perimeter_event_meta 
          ? <EventCell
              event={site.perimeter_event_meta }
              focus={focus}
            />
          : ""
      )
    },
    className: "alarm-type",
  },
  {
    key: "AREA",
    content: ({ area, areaContainsFilter }) => {
      if (area.area) {
        return <span className={`text-muted ${areaContainsFilter ? "contains-filter" : ""}`}>{area.area}</span>
      } else {
        return "";
      }
    },
  },
  {
    key: "AREA_NAME",
    content: ({ area, areaNameContainsFilter }) => {
      if (area.area_name) {
        return <span className={`text-muted ${areaNameContainsFilter ? "contains-filter" : ""}`}>{area.area_name}</span>
      } else {
        return "";
      }
    },
  },
  {
    key: "AREA_STATE",
    content: ({ area, focus, areaStateContainsFilter }) => {
      return (
        area.area_state_event_meta
          ? <EventCell
              event={area.area_state_event_meta}
              focus={focus}
              underline={areaStateContainsFilter}
              onClick={() => {}}
            />
          : ""
      );
    },
    className: "alarm-type",
  },
  {
    key: "LAST_STATE_EVENT",
    content: ({ area, lastStateEventContainsFilter }) => {
      if (area.last_state_event) {
        return <span className={`text-muted ${lastStateEventContainsFilter ? "contains-filter" : ""}`}>{area.last_state_event}</span>
      } else {
        return "";
      }
    },
  },
  {
    key: "LAST_STATE_CHANGE",
    content: ({ site, area, lastStateChangeContainsFilter }) => {
      if (area.last_state_change) {
        let colorClass = "";
        const lastStateChange = new moment(area.last_state_change);
        const daysAgo = -lastStateChange.diff(today, "day");
        if (daysAgo > 0 && daysAgo <= 6) {
          colorClass = "last-state-change-orange muted";
        } else if (daysAgo > 6) {
          colorClass = "last-state-change-red muted";
        } else {
          colorClass = "text-muted";
        }

        if (!site.timezone) {
          return (
            <div className={`${colorClass} ${lastStateChangeContainsFilter ? "contains-filter" : ""}`}>
              {lastStateChange.format("DD/MM/YYYY HH:mm")}
            </div>
          );
        } else {
          return (
            <div className={`${colorClass} ${lastStateChangeContainsFilter ? "contains-filter" : ""}`}>
              <TimezoneDate date={area.last_state_change} timezone={site.timezone} format="DD/MM/YYYY HH:mm" hideTimezone />
            </div>
          );
        }
      } else {
        return ""
      }
    },
  },
  {
    key: "EXPAND",
    content: null
  }
];
 
export default ChildRowColumns;